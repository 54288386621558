<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
				<a-card title="审核任务">
					<a-card-grid v-for="item in auditTaskList" style="width:20%;text-align:center;" @click="NewTab(item.url)" @mouseover="mouseOver(item.id)" @mouseleave="mouseLeave" :style="{'backgroundColor': style == item.id ? '#78edff' : ''}">
					  <a-icon :type="item.icon" />
					  {{l(item.name)}}
					</a-card-grid>
				</a-card>
				<a-card title="问题" style="margin-top: 10px;">
					<a-card-grid v-for="item in issueList" style="width:20%;text-align:center;" @click="NewTab(item.url)" @mouseover="mouseOver(item.id)" @mouseleave="mouseLeave" :style="{'backgroundColor': style == item.id ? '#78edff' : ''}">
					  <a-icon :type="item.icon" />
					  {{l(item.name)}}
					</a-card-grid>
				</a-card>
            </a-card>
        </a-config-provider>
    </div>
</template>
<script>
	import { AppComponentBase } from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import { IssueProgressServiceProxy } from '../../../shared/service-proxies';

	let _this;
	export default {
	    name: "Report-inventory",
	    mixins: [AppComponentBase],
	    components: {
	        
	    },
	    created() {
	        _this = this;
	        this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
	    },
	    mounted() {
	        this.getData()
	    },
	    data() {
	        return {
	            zh_CN,
				style: undefined,
				auditTaskList: [
					{name: '部门审核完成率', url: '/app/report/Departmental-completion' , id: 1, icon: 'bar-chart'},
					{name: '审核员未审核数量', url: '/app/report/Incomplete-auditors' , id: 2, icon: 'bar-chart'},
					{name: '审核结果符合率', url: '/app/report/Results-match' , id: 3, icon: 'bar-chart'},
					{name: '不符合项的数量', url: '/app/report/Nonconformances' , id: 4, icon: 'bar-chart'},
					{name: '审核项类型符合率', url: '/app/report/Audit-item-type' , id: 5, icon: 'bar-chart'},
					{name: '层级月视图', url: '/app/report/Level-Monthly-Miew' , id: 13, icon: 'bar-chart'}
				],
				issueList: [
					{name: '部门问题关闭率', url: '/app/report/Departmental-issues' , id: 6, icon: 'bar-chart'},
					{name: '问题分类关闭率', url: '/app/report/Issue-type' , id: 7, icon: 'bar-chart'},
					{name: '重复问题关闭率', url: '/app/report/Duplicate-issues' , id: 8, icon: 'profile'},
					{name: '24小时内问题关闭率', url: '/app/report/Hours-issue' , id: 9, icon: 'bar-chart'},
					{name: '30天未关闭问题清单', url: '/app/report/NoClose-issue' , id: 10, icon: 'profile'},
					{name: '30天未关闭问题-部门', url: '/app/report/Departmental-NoClose-issue' , id: 11, icon: 'bar-chart'},
					{name: '30天未关闭问题-负责人', url: '/app/report/Responsible-NoClose-issue' , id: 12, icon: 'bar-chart'},
					{name: '部门问题数量统计', url: '/app/report/Statistics-on-sectoral-issues' , id: 14, icon: 'bar-chart'}
				]
	        }
	    },
	    methods: {
			NewTab(url) {
				this.$router.push({ path: url});
				this.style = undefined;
			},
			
			mouseOver(number) {
				this.style = number;
				console.log(this.style)
			},
			
			mouseLeave() {
				this.style = undefined;
			}
	    }
	}
</script>

<style>
</style>
